import { mock } from 'src/utils/axios';
import type { Role } from 'src/models/role';

let roles: Role[] = [
  {
    id: 1,
    name: 'Administrator',
    users: 3
  },
  {
    id: 2,
    name: 'Manager',
    users: 2
  },
  {
    id: 3,
    name: 'Owner',
    users: 67
  },
  {
    id: 4,
    name: 'Tenant',
    users: 432
  }
];

mock.onGet('/api/roles').reply(() => {
  return [200, { roles }];
});

mock.onGet('/api/roles/:id').reply((config) => {
  const { id } = config.params;
  const role = roles.find((item) => item.id === id);

  return [200, { user: role }];
});
