import { mock } from 'src/utils/axios';
import { Contract } from '../models/contract';

let items: Contract[] = [
  {
    id: 1,
    notes: 'Contract',
    assetId: 1
  },
  ...Array.from({ length: 20 }, (_, i) => ({
    id: i + 2,
    notes: `Contract ${i + 2}`,
    assetId: i + 2
  }))
];
mock.onGet('/api/contracts').reply((config) => {
  return [200, { items }];
});

mock.onGet('/api/contracts/:id').reply((config) => {
  const { id } = config.params;
  const item = items.find((item) => item.id === id);

  return [200, { item }];
});
