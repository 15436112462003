import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Authenticated from '../components/Authenticated';
import AccentSidebarLayout from '../layouts/AccentSidebarLayout';
import dashboardsRoutes from './dashboard';
import managementRoutes from './management';
import contractsRoutes from './contracts';
import assetsRoutes from './assets';
import reportsRoutes from './reports';
import invoicesRoutes from './invoices';
import paymentVouchersRoutes from './paymentVouchers';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const baseRoutes = [
  {
    path: '/',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        children: dashboardsRoutes
      },
      {
        path: 'assets',
        children: assetsRoutes
      },
      {
        path: 'reports',
        children: reportsRoutes
      },
      {
        path: 'contracts',
        children: contractsRoutes
      },
      {
        path: 'invoices',
        children: invoicesRoutes
      },
      {
        path: 'payment-vouchers',
        children: paymentVouchersRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default baseRoutes;
