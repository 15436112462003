import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Assets = Loader(
  lazy(() => import('src/content/general/Assets/AssetList'))
);
const AssetCreate = Loader(
  lazy(() => import('src/content/general/Assets/AssetCreate'))
);
const AssetView = Loader(
  lazy(() => import('src/content/general/Assets/AssetView'))
);
const AssetEdit = Loader(
  lazy(() => import('src/content/general/Assets/AssetEdit'))
);

const assetsRoutes = [
  {
    path: '',
    element: <Assets />
  },
  {
    path: 'create',
    element: <AssetCreate />
  },
  {
    path: ':assetId',
    element: <AssetEdit />
  },
  {
    path: ':assetId/view',
    element: <AssetView />
  }
];

export default assetsRoutes;
