import './products';
import './dashboards';
import './crypto_orders';
import './jobs';
import './calendar';
import './projects_board';
import './mailbox';
import './projects';
import './roles';
import './reports';
import './contracts';

import { mock } from 'src/utils/axios';
mock.onAny().passThrough();
