import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const ContractList = Loader(
  lazy(() => import('src/content/general/Contracts/ContractList'))
);
const ContractCreate = Loader(
  lazy(() => import('src/content/general/Contracts/ContractCreate'))
);
const ContractEdit = Loader(
  lazy(() => import('src/content/general/Contracts/ContractCreate'))
);
const ContractView = Loader(
  lazy(() => import('src/content/general/Contracts/ContractView'))
);
const contractsRoutes = [
  {
    path: '',
    element: <ContractList />
  },
  {
    path: 'create',
    element: <ContractCreate />
  },
  {
    path: ':contractId',
    element: <ContractEdit />
  },
  {
    path: ':contractId/view',
    element: <ContractView />
  }
];

export default contractsRoutes;
