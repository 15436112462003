import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Reports = Loader(lazy(() => import('src/content/general/Reports')));
const reportsRoutes = [
  {
    path: '',
    element: <Reports />
  }
];

export default reportsRoutes;
