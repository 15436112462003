import { RouteObject } from 'react-router';

import BaseLayout from 'src/layouts/BaseLayout';
import accountRoutes from './account';
import baseRoutes from './base';

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  }
];

export default router;
