import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import objectArray from 'src/utils/objectArray';
import { Country } from '../models/country';

interface CountriesState {
  countries: Country[];
}

const initialState: CountriesState = {
  countries: []
};

const slice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    getCountries(
      state: CountriesState,
      action: PayloadAction<{ countries: Country[] }>
    ) {
      const { countries } = action.payload;

      state.countries = countries;
    }
  }
});

export const reducer = slice.reducer;

export const getCountries = (): AppThunk => async (dispatch) => {
  const response = await axios.get<{ countries: Country[] }>(
    '/settings/countries'
  );

  dispatch(slice.actions.getCountries(response.data));
};

export default slice;
