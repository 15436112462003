import { mock } from 'src/utils/axios';
import { Report, ReportType } from '../models/report';

let reports: Report[] = [
  {
    id: 1,
    name: 'Income',
    description: 'Income',
    type: 'income'
  }
];

let types: ReportType[] = ['income'];

mock.onGet('/api/reports/types').reply(() => {
  return [200, { types }];
});

mock.onGet('/api/reports/').reply((config) => {
  // const { type } = config.params;
  //const reports = reports; //.find((item) => item.type === type);

  return [200, { reports }];
});

mock.onGet('/api/reports/:id').reply((config) => {
  const { id } = config.params;
  const report = reports.find((item) => item.id === id);

  return [200, { asset: report }];
});
