import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Invoices
const Invoices = Loader(
  lazy(() => import('src/content/general/Invoices/InvoicesList'))
);
const assetsRoutes = [
  {
    path: '',
    element: <Invoices />
  }
];

export default assetsRoutes;
